import React from 'react'

// Images
import blueChevronLeft from 'img/snel_blue_chevron_left.svg'
import blueChevronRight from 'img/snel_blue_chevron_right.svg'
import whiteChevronLeft from 'img/snel_white_chevron_left.svg'
import whiteChevronRight from 'img/snel_white_chevron_right.svg'

// Third Party
import styled from 'styled-components'

const NextArrowWrapper = styled.div`
  cursor: pointer;

  ${props => props.middle ? `
    top: 0;
    bottom: 0;
    right: -20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  ` : `
    top: 85px;
    right: -50px;
  `}
`

export const NextArrow = ({ white, middle, className, onClick }) => {
  return (
    <NextArrowWrapper middle={middle} onClick={onClick} className={`d-lg-flex d-none position-absolute ${className ? `${className}` : ``}`}>
      <img src={white ? whiteChevronRight : blueChevronRight} alt="" />
    </NextArrowWrapper>
  )
}

const PrevArrowWrapper = styled.div`
  cursor: pointer;

  ${props => props.middle ? `
    top: 0;
    bottom: 0;
    left: -50px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  ` : `
    top: 85px;
    left: -50px;
  `}
`

export const PrevArrow = ({ white, middle, className, onClick }) => {
  return (
    <PrevArrowWrapper middle={middle} onClick={onClick} className={`d-lg-flex d-none position-absolute ${className ? `${className}` : ``}`}>
      <img src={white ? whiteChevronLeft : blueChevronLeft} alt="" />
    </PrevArrowWrapper>
  )
}