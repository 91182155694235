/* eslint-disable react/no-array-index-key */
import React from 'react'
// import { graphql, useStaticQuery } from 'gatsby'

// Third Party
import Slider from 'react-slick'
import styled from 'styled-components'
import GatsbyImage from 'gatsby-image'

const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
    align-items: center;
  }

  .logo-container {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 991px) {
    img {
      height: 75%;
      width: 75%;
    }
  }
`

const StyledImg = styled.img`
  height: 65%;
  width: 65%;
`

const StyledGatsbyImage = styled(GatsbyImage)`
  height: 65%;
  width: 65%;
`

const BrandSlider = ({ className, data, title }) => {
  // const {
  //   allWordpressWpMerken: { edges: brandList },
  // } = useStaticQuery(graphql`
	// 	{
	// 		allWordpressWpMerken(filter: {wpml_current_locale: {eq: "nl_NL"}}) {
	// 			edges {
	// 				node {
	// 					acf {
	// 						logo {
	// 							url {
	// 								localFile {
	// 									publicURL
	// 								}
	// 							}
	// 						}
	// 					}
	// 					title
	// 				}
	// 			}
	// 		}
	// 	}
  // `)

  // Somehow declaring the slides above the render function suppresses the key unique error
  const Slides = data.map((brand, index) => (
    <div key={index}>
      <div className="logo-container d-flex justify-content-center align-items-center">
        {brand.node.acf.logo.localFile.childImageSharp ? (
          <StyledGatsbyImage alt="src" fluid={brand.node.acf.logo.localFile.childImageSharp.fluid} />
        ) : (
          <StyledImg alt="src" src={brand.node.acf.logo.localFile.publicURL} />
        )}
      </div>
    </div>
  ))

  return (
    <div className={`brand-slider ${className && `${className}`}`}>
      <div className="container">
        <div className="brand-slider-title-container d-flex justify-content-center">
          <h2 className="brand-slider-title pb-5 font-size-xl font-weight-xl">{title}</h2>
        </div>
        <StyledSlider
          infinite
          speed={200}
          slidesToShow={5}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={6000}
          responsive={[{
            breakpoint: 576,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          }]}
        >
          {Slides}
        </StyledSlider>

      </div>
    </div>
  )
}

export default BrandSlider
