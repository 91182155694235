import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Third Party
import $ from 'jquery'
import '../../libraries/instafeed'
import styled from 'styled-components'

if(typeof window !== 'undefined') {
  window.$ = $
  window.jQuery = $
}

const InstagramWrapper = styled.div`
  .eapps-instagram-feed-posts-grid-load-more-container {
    display: none !important;
  }
`

const InstagramContext = styled.div`
  left: -40px;

  @media (max-width: 991px) {
    left: 0; 
  }
`

const Instagram = ({ instagram, className }) => {
  const {
    site: {
      siteMetadata: {
        baseUrl
      }
    }
  } = useStaticQuery(graphql`{
    site {
      siteMetadata {
        baseUrl: siteWpUrl
      }
    }
  }`)

  return (
    <InstagramWrapper className={`${className ? `${className}` : ``}`}>
      <InstagramContext className="d-flex container position-relative" />
      <InstagramFeed instagramName={instagram} baseUrl={baseUrl} />
    </InstagramWrapper>
  )
}

class InstagramFeed extends React.Component {
  componentDidMount() {
    const { instagramName,  baseUrl } = this.props

    this.$el = $(this.el)

    this.$el.eappsInstagramFeed({
      api: `${baseUrl}/instashow/api/index.php`,
      source: `@${instagramName}`,
      width: 'auto',
      layout: 'grid',
      columns: 2,
      rows: 3,
      limit: 6,
      lang: 'nl',
      responsive: { 
        '991': { 
          'columns': 2,
          'rows': 3,
        },
        '767': {
          'columns': 2,
          'rows': 3,
        }
      }
    });
  }

  render() {
    return (
      <div className="brand mx-0">
        <div className="px-0">
          <div 
            ref={el => { this.el = el }}
          />
        </div>
      </div>
    )
  }
}


export default Instagram