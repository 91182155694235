/* eslint-disable react/no-array-index-key */
import React from 'react'

// CSS
import styled from 'styled-components'

// Components
import { NextArrow, PrevArrow } from 'components/Common/Arrows'

// Third Party
import Slider from 'react-slick'
import parse from 'html-react-parser'

const StyledSlider = styled(Slider)`
  .slick-arrow {
    ::before {
      content: none;
    }
  }

  .slick-list {
    overflow: hidden;
    margin: 0 -10px;
  }

  .slick-slide {
    margin: 0 10px;

    @media (max-width: 767px) {
      padding-left: 25px;
    }
  }

  .slick-track {
    display: flex;
  }
`

const CheckMarkText = styled.div`
  p {
    color: #ffffff;
    font-size: 16px;
  }
`

const TextSlider = ({ arrows, middle, white, content }) => {

  return (
    <div className="w-100">
      <StyledSlider
        infinite
        speed={600}
        slidesToShow={3}
        slidesToScroll={1}
        autoplay
        autoplaySpeed={8000}
        nextArrow={arrows ? (<NextArrow middle={middle} white={white} />) : (<div />)}
        prevArrow={arrows ? (<PrevArrow middle={middle} white={white} />) : (<div />)}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1
            }
          }
        ]}
      >
        {content.map((tip, index) => (
          <div key={index}>
            <div className="px-4">
              {tip.text ? (
                <p className="check-mark pl-4 color-text-light font-size-m">
                  {tip.text}
                </p>
              ) : (
                <CheckMarkText className="check-mark pl-4 color-text-light font-size-m">
                  {parse(tip.content)}
                </CheckMarkText>
              )}
            </div>
          </div>
        ))}
      </StyledSlider>
    </div>
  )
}

export default TextSlider