/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

// CSS
import './VerticalUpdates.scss'

// Components
import Instagram from 'components/Common/Instagram'

const BrandSlider = ({ className }) => {
  return (
    <div className={`vertical-updates ${className && `${className}`}`}>
      <div className="row">
        <div className="col-12 py-5">
          <h2 className="m-0 font-size-xl font-weight-xl color-text-main">Brandwise</h2>
          <h2 className="m-0 font-size-xl font-weight-xl color-text-contrast">updates</h2>
        </div>
        <div className="col-12 green-line-left pl-lg-5">
          <Instagram instagram="brandwisegroup" />
        </div>
      </div>
    </div>
  )
}

export default BrandSlider
