/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { navigate } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// CSS
import './CampaignGridItem.scss'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const Overlay = styled.div`
  transition: 0.25s;
  min-height: 160px;
`

const StyledBackgroundImage = styled(BackgroundImage)`
  transition: transform 0.25s ease;
`

const CampaignWrapper = styled.div`
  transition: 0.25s;
  :hover {
    ${StyledBackgroundImage} {
      transform: scale(1.2);
    }
  }
`

const ImgWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
`

const CampaignGridItem = ({ campaign, className }) => {
  return (
    <div role="button" onClick={() => navigate(campaign.path)} onKeyDown={() => navigate(campaign.path)} className={`campaign-grid-item d-flex justify-content-center cursor-pointer ${className ? `${className}` : ``}`}>
      <CampaignWrapper className="campaign-wrapper position-relative">
        <ImgWrapper>
          <StyledBackgroundImage className="w-100 h-100" fluid={campaign.acf.information.image.localFile.childImageSharp.fluid} />
        </ImgWrapper>
        <Overlay className="campaign-overlay position-absolute color-background-main-faded w-100 p-4">
          <div className="position-relative mb-3 campaign-overlay-header d-flex">
            <img alt="" src={campaign.acf.information.dienst.acf.icon.localFile.publicURL} />
            <div>
              <h2 className="pl-3 pb-1 m-0 font-size-sm font-weight-xl color-text-light">{parse(campaign.title)}</h2>
              <p className="pl-3 m-0 font-size-sm font-weight-xl color-text-contrast">
                {parse(campaign.acf.information.brand.name)}
              </p>
            </div>
          </div>
          <div className="campaign-overlay-content">
            <p className="font-size-sm color-text-light mb-0">{campaign.acf.information.sub_title}</p>
          </div>
        </Overlay>
      </CampaignWrapper>
    </div>
  )
}

export default CampaignGridItem
