/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { BackgroundColor, ButtonAlt, Video } from 'components/Elements'
import Content from 'components/Content'
import CustomLink from 'components/CustomLink'
import CampaignGridItem from 'components/Campaign/CampaignGridItem'
import BrandSlider from 'components/Brands/BrandSlider'
import VerticalUpdates from 'components/Updates/VerticalUpdates'
import TextSlider from 'components/TextSlider'
// CSS
import 'styles/HomePage.scss'
// Third Party
import Slider from 'react-slick'
import parse from 'html-react-parser'
import styled from 'styled-components'

const ContentContainer = styled.div`
  p {
    color: #ffffff;
  }
`

const HomeTemplate = ({ data }) => {
  const {
    wordpressPage: {
      acf: {
        merken_title: brandsTitle,
        power_of_human_connection: powerOfHumanConnection,
        our_work: {
          title: ourWorkTitle,
          link: {
            title: ourWorkLinkTitle,
            url: ourWorkLinkUrl,
          },
          relations_with_taxonomy: campaigns,
        },
        mission_group: missionGroup,
        did_you_know: {
          title: didYouKnowTitle,
          tip: tips,
        },
        ssi_network: ssiNetwork,
        wordpress_30_years_experience: yearsExperience,
        brandwise,
      },
    },
    allWordpressWpCampagnes: {
      totalCount,
    },
    allWordpressWpMerken: {
      edges: brandList
    }
  } = data

  return (
    <Layout extraFooterPiece>
      <SEO title="Home" description="Brandwise biedt een breed scala aan field marketing & sales diensten onder één dak. We leveren totaaloplossingen van strategie tot executie waarbij menselijke connectie centraal staat om merken, consumenten en retailers te verbinden." />
      <div className="home-page">
        <section className="hero container mt-5">
          <div className="row justify-content-between">
            <div className="col-12 col-md-5 col-xl-5 mb-5 mb-md-0 brandwise d-flex justify-content-start">
              <div className="w-100">
                <h2 className="w-100 ml-n1 mb-0 mt-0 mt-lg-5 pt-0 pt-lg-5 font-size-xxxl font-weight-xl">
                  {brandwise.title}
                </h2>
                <div>
                  <Slider
                    infinite
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    autoplay
                    autoplaySpeed={4000}
                  >
                    {brandwise.sub_title.map((item, index) => (
                      <p key={index} className="m-0 w-100 color-text-contrast font-size-l font-weight-m">{item.text}</p>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 position-relative">
              <Img className="hero-image" fluid={brandwise.image.localFile.childImageSharp.fluid} />
            </div>
            <div className="col-lg-12 col-xl-10 mx-auto position-relative service-wrapper">
              <div className="container">
                <div className="row justify-content-center">
                  {brandwise.services.map((service, index) => (
                    <Link to={service.link.url} key={index} className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mb-lg-3 mb-3 mb-md-3 mt-md-1 mt-0 services-taxonomy">
                      <div className="card py-3 px-5 px-lg-5 py-lg-5 d-flex justify-content-center">
                        <div className="card-icon">
                          <img alt="" src={service.image.localFile.publicURL} />
                        </div>
                        <div className="card-title px-2 text-lowercase">
                          <Content content={service.link.title} />
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <BackgroundColor className="py-5 color-background-light">
          <div className="col-12 pt-5 pt-lg-0 col-lg-6 pb-4 pb-lg-0">
            <h2 className="pb-3 pr-xl-5 font-size-xl font-weight-xl">{yearsExperience.title}</h2>
            <Content className="color-text-secondary font-size-sm" content={yearsExperience.content} />
          </div>
          <div className="col-12 my-lg-0 my-5 py-5 py-lg-0 years-experience-image-container video-container col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-center">
            <Img fluid={yearsExperience.image.localFile.childImageSharp.fluid} />
          </div>
        </BackgroundColor>

        <BackgroundColor className="py-5 color-background-secondary">
          <div className="col-12 video-container col-lg-6 d-flex justify-content-center justify-content-lg-start align-items-center">
            <Video
              // replacement={powerOfHumanConnection.replacement_image.localFile.childImageSharp.fluid} 
              url={powerOfHumanConnection.video} title={powerOfHumanConnection.title}
            />
          </div>
          <div className="col-12 pt-2 pt-lg-0 col-lg-6">
            <h2 className="pb-3 font-size-xl font-weight-xl">{powerOfHumanConnection.title}</h2>
            <Content className="color-text-secondary power-of-human-connection-content font-size-sm" content={powerOfHumanConnection.content} />
            <CustomLink className="color-text-contrast font-weight-xl underline" to={powerOfHumanConnection.cta.url}>
              {`> ${powerOfHumanConnection.cta.title}`}
            </CustomLink>
          </div>
        </BackgroundColor>

        <BrandSlider className="py-5" data={brandList} title={brandsTitle} />

        <div className="py-5 container">
          <h2 className="font-size-xl py-4 font-weight-xl">{ourWorkTitle}</h2>
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="row">
                {campaigns.map((campaign, index) => (
                  <CampaignGridItem key={index} campaign={campaign} className="col-12 col-md-6 mb-4" />
                ))}
              </div>
            </div>
            <div className="col-12 col-lg-4 d-flex justify-content-center pb-4">
              <div className="quote color-background-main d-flex flex-wrap justify-content-center align-items-center w-100 h-100">
                <ContentContainer className="px-4 py-5">
                  <h2 className="text-center pb-3 font-weight-xl color-text-light">{missionGroup.mission_label}</h2>
                  {parse(missionGroup.missions)}
                </ContentContainer>
              </div>
            </div>
          </div>
          <div className="d-flex py-2 pb-5 justify-content-center">
            <ButtonAlt className="px-3 font-size-ms" to={ourWorkLinkUrl}>
              {`${ourWorkLinkTitle} (${totalCount})`}
            </ButtonAlt>
          </div>
        </div>

        <BackgroundColor className="py-5 color-background-main">
          <div className="col-12">
            <h2 className="color-text-light text-center font-size-xl font-weight-xl">{didYouKnowTitle}</h2>
          </div>
          <div className="w-100 empty-space-50" />
          <div className="row justify-content-center w-100">
            <TextSlider arrows middle white content={tips} />
          </div>
        </BackgroundColor>
        <div className="py-5 container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="row">
                <div className="col-12 py-5">
                  <h2 className="m-0 font-size-xl font-weight-xl color-text-main">{ssiNetwork.title_group.title_1}</h2>
                  <h2 className="m-0 font-size-xl font-weight-xl color-text-contrast">
                    {ssiNetwork.title_group.title_2}
                  </h2>
                </div>
                <div className="col-12 color-text-secondary" dangerouslySetInnerHTML={{ __html: ssiNetwork.content }} />
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <VerticalUpdates />
            </div>
          </div>
        </div>
        <div className="empty-space-200" />
      </div>
    </Layout>
  )
}

export default HomeTemplate

export const query = graphql`
	query HomeTemplate($wordpress_id: Int!, $language: String!) {
		wordpressPage(wordpress_id: { eq: $wordpress_id }) {
			...HomeFrag
		}

		allWordpressWpCampagnes(filter: {wpml_current_locale: {eq: $language}}) {
			totalCount
		}

    allWordpressWpMerken(filter: {wpml_current_locale: {eq: $language}}) {
      ...MerkenFrag
    }
	}
`
